.coming-soon {
  margin-bottom: 3rem;
  position: relative;
}

.coming-soon-background {
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  left: 0;
  background-image: url("../../../src/utils/images/running.gif");
  background-size: cover;
  filter: blur(40px);
  z-index: -2;
}

.coming-soon-background2 {
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  left: 0;
  background-image: url("../../../src/utils/images/running.gif");
  background-size: cover;
  opacity: 0.4;
  z-index: -1;
}

.coming-soon-content {
  height: 30rem;
  justify-content: center;
}

.coming-soon-title {
  font-size: 4rem;
  font-weight: 700;
  width: max-content;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  user-select: none;
}

.coming-soon-title span {
  font-family: "Wolkerjuiced";
}
