._header.page-container {
  height: var(--header-height);
  gap: 2rem;
}

.header-logo {
  height: calc(var(--header-height) / 2);
  width: calc(var(--header-height) / 2);
  background-image: url("../../utils/images/head.png");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}

.header-option {
  font-weight: 900;
  font-family: Wolkerjuiced;
  font-size: 1.2rem;
}
