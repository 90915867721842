@font-face {
  font-family: "Wolkerjuiced";
  font-style: "normal";
  font-weight: 500;
  src: url("./utils/font/Wolkerjuiced.ttf");
}

html {
  background: var(--background);
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

* {
  font-variant-ligatures: none;
  padding: 0px;
  margin: 0px;
  color: white;
  font-family: sans-serif;
  box-sizing: border-box;
  word-wrap: break-word;
}

body {
  margin: 0;
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --background: #000;
  --text: #fff;
  --accent: lightgreen;
  --page-width: 800px;
  --header-height: 6rem;
}

a {
  color: unset;
  text-decoration: none;
}

.hover {
  cursor: pointer;
  user-select: none;
}

.hover:hover {
  color: var(--accent);
}

.page-wrapper {
  padding: 3rem 0;
  flex-grow: 1;
}

@media (max-width: 700px) {
  .page-container {
    padding: 0 1rem !important;
  }
}

.page-container {
  padding: 0 2rem;
  box-sizing: content-box;
  margin: auto;
  max-width: var(--page-width);
}

.flex {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
